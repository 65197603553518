import { BehaviorSubject, Observable, throttleTime } from 'rxjs';
import { BluetoothService } from '@services/bluetooth/bluetooth.service';
import { DEFAULT_RSSI, Device, DeviceDTO, mapDeviceToDTO } from '@models/device';
import { DataService } from '@services/data/data.service';
import { DeviceStatus } from '@enums/device-status.enum';
import { FirebaseCollections } from '@enums/firebase-collections.enum';
import { Injectable, inject } from '@angular/core';
import { NotificationType } from '@app/enums/notification-type.enum';
import { RemoteConfig } from '@app/enums/remote-config.enum';
import { RemoteConfigService } from '@services/remote-config/remote-config.service';
import { ScanResult } from '@capacitor-community/bluetooth-le';
import { User } from '@models/user';
import { UsersService } from '../users/users.service';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends DataService {
  public connectedDevice$ = new BehaviorSubject<Device | null>(null);
  public scanResults$ = new BehaviorSubject<Set<ScanResult>>(new Set());
  private batteryNotificationSent = false;
  private connectedDevice: Device | null = null;
  private initInProgress = false;
  private readonly bluetoothService: BluetoothService = inject(BluetoothService);
  private readonly remoteConfig: RemoteConfigService = inject(RemoteConfigService);
  private readonly usersService: UsersService = inject(UsersService);
  private scanResults: Set<ScanResult> = new Set();

  constructor() {
    super();
    this.toastEnabled = false;
    if (this.platform.is('capacitor')) {
      this.usersService.entity.pipe(throttleTime(1000)).subscribe(async (user: User | null) => {
        if (user) {
          this.initializeDevices();
        } else {
          if (this.entity.value) {
            this.disconnectDevice(this.entity.value);
          }
        }
      });
    } else {
      // on web we don't need to initialize the devices
      this.connectedDevice = null;
      this.connectedDevice$.next(null);
    }
  }

  public get currentDevice(): Observable<Device | null> {
    return this.connectedDevice$.asObservable();
  }

  public set currentDevice(device: Device | null) {
    this.connectedDevice = device;
    this.connectedDevice$.next(device);
  }

  public get scannedDevices(): Observable<Set<ScanResult>> {
    return this.scanResults$.asObservable();
  }

  public async isBonded(device: Device): Promise<boolean> {
    if (device && device.deviceId) {
      return this.bluetoothService.isBonded(device.deviceId);
    } else {
      return false;
    }
  }

  public getDevices(): Observable<Device[]> {
    return this.getDocuments(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.DEVICES}`,
      null,
      []
    ) as Observable<Device[]>;
  }

  public getDevice(id: string): Observable<Device> {
    return this.getDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.DEVICES}/${id}`
    ) as Observable<Device>;
  }

  public async addDevice(data: DeviceDTO): Promise<Device> {
    return await this.addDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.DEVICES}`,
      FirebaseCollections.DEVICES,
      data
    );
  }

  public async updateDevice(id: string, data: DeviceDTO): Promise<Device> {
    return this.updateDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.DEVICES}/${id}`,
      FirebaseCollections.DEVICES,
      data
    );
  }
  /*
   * Used to ensure connected devices is only stored once
   * Subscription is necessary, because getDocuments returns an observable
   */
  public async upsertDevice(device: Device): Promise<void> {
    return new Promise(resolve => {
      const deviceDTO = mapDeviceToDTO(device);
      const subscription = this.getDevices().subscribe(async (storedDevices: Device[]) => {
        const filteredDevices = storedDevices.filter(
          (storedDevice: Device) => storedDevice.deviceId === deviceDTO.deviceId
        );
        if (filteredDevices.length > 0 && filteredDevices[0].id) {
          await this.updateDevice(filteredDevices[0].id, deviceDTO);
        } else {
          await this.addDevice(deviceDTO);
        }
        subscription.unsubscribe();
        resolve();
      });
    });
  }

  public async deleteDevice(id: string): Promise<void> {
    await this.deleteDocument(
      `${FirebaseCollections.USERS}/${this.usersService.entity.value?.uid}/${FirebaseCollections.DEVICES}/${id}`,
      FirebaseCollections.DEVICES
    );
    this.connectedDevice = null;
    this.connectedDevice$.next(null);
    return;
  }

  /*
   * Use to retrieve stored devices from firebase
   * In case a stored device is available, try to connect directly
   * If stored device is not found, scan for connected devices
   */
  public initializeDevices(): void {
    this.getDevices().subscribe(async (devices: Device[]) => {
      console.log('🚀 ~ DevicesService ~ this.getDevices ~ devices:', devices);
      // if there are no devices, set connected device to null
      if (devices.length === 0) {
        this.connectedDevice = null;
        this.connectedDevice$.next(null);
      }

      if (devices.length > 0 && !this.initInProgress) {
        this.initInProgress = true;
        // first device is the stored device
        this.checkDevice(devices[0]);
        this.initInProgress = false;
      }
    });
  }

  /*
   * Use to restore device connection/state after app restart and coming back from background
   */
  public async restoreDevice(): Promise<void> {
    if (this.connectedDevice) {
      this.checkDevice(this.connectedDevice);
    }
  }

  /*
   * Used to request a LE scan for devices.
   */
  public async searchDevices(): Promise<void> {
    this.scanResults = new Set();
    this.scanResults$.next(this.scanResults);
    await this.bluetoothService.searchDevices(this.scanResults);
    this.scanResults$.next(this.scanResults);
  }

  /*
   * Used to request a LE scan for devices.
   */
  public async connectToDevice(device: Device): Promise<Device> {
    device.status = DeviceStatus.CONNECTING;
    this.connectedDevice = await this.bluetoothService.connectToDevice(device, this.onDisconnect.bind(this));
    // if device is previously connected and stored so keep id
    if (device.id) {
      this.connectedDevice.id = device.id;
    }
    this.connectedDevice$.next(this.connectedDevice);
    this.updateDeviceData(this.connectedDevice);
    this.initInProgress = false;
    return this.connectedDevice;
  }

  /*
   * Used to fetch data from a device and update the connected device.
   */
  public async fetchDeviceData(): Promise<void> {
    if (this.connectedDevice) {
      const updatedDevice = await this.bluetoothService.fetchDeviceData(this.connectedDevice);
      this.connectedDevice = { ...updatedDevice };
      this.connectedDevice$.next(this.connectedDevice);
    }
  }

  /*
   * Used to fetch data stop alarm service.
   * TODO - check why this is not working
   */
  public async fetchAlarmData(): Promise<number | null> {
    if (this.connectedDevice) {
      const data = await this.bluetoothService.fetchAlarmData(this.connectedDevice);
      return data;
    } else {
      return null;
    }
  }

  /*
   * Used to disconnect from a device on purpose.
   */
  public async disconnectDevice(device: Device): Promise<void> {
    console.log('🚀 ~ DevicesService ~ disconnectDevice ~ disconnectDevice:');
    if (device) {
      await this.bluetoothService.disconnectDevice(device);
    }
  }

  /*
   * Used to retrieve list of services from a device.
   */
  public async getServices(): Promise<void> {
    if (this.connectedDevice) {
      const services = await this.bluetoothService.getServicesFromDevice(this.connectedDevice.deviceId);
      this.connectedDevice.services = services;
      this.connectedDevice$.next(this.connectedDevice);
    }
  }

  /*
   * Used to subscribe to services.
   */
  public async subscribeToServices(): Promise<void> {
    if (this.connectedDevice) {
      await this.bluetoothService.subscribeToService(this.connectedDevice);
    }
  }

  /*
   * Used to update the BLE plugin config.
   */
  public async updateBleConfig(sessionId: string): Promise<void> {
    const token = await this.usersService.getAuthToken();
    if (token) {
      const url = `${environment.firebase.httpEndpoint}/sessions/${sessionId}/emergency`;
      await this.bluetoothService.updateBleConfig({
        url,
        authToken: token
      });
    }
  }

  /*
   * Used to unsubscribe from services.
   */
  public async unsubscribeFromServices(): Promise<void> {
    if (this.connectedDevice) {
      await this.bluetoothService.unSubscribeFromService(this.connectedDevice);
    }
  }

  /*
   * Used to toggle the light on the device.
   */
  public async toggleLight(): Promise<void> {
    if (this.connectedDevice) {
      await this.bluetoothService.toggleLight(this.connectedDevice, this.connectedDevice.ledIsOn || false);
      this.connectedDevice.ledIsOn = !this.connectedDevice.ledIsOn;
    }
  }

  /*
   * Used to toggle the alarm on the device.
   */
  public async toggleAlarm(): Promise<void> {
    if (this.connectedDevice) {
      await this.bluetoothService.toggleAlarm(this.connectedDevice, this.connectedDevice.alarmIsOn || false);
      this.connectedDevice.alarmIsOn = !this.connectedDevice.alarmIsOn;
    }
  }

  /*
   * Used to check if a device is already connected, if so just add reference.
   * If device was connected previously, try to reconnect.
   * If device was not connected previously, try to connect.
   */
  private async checkDevice(storedDevice: Device): Promise<void> {
    if (storedDevice !== null) {
      storedDevice.status = DeviceStatus.CONNECTING;
      storedDevice.batteryLevel = undefined;
      storedDevice.rssi = DEFAULT_RSSI;
      this.connectedDevice = storedDevice;
      this.connectedDevice$.next(storedDevice);
      // check if device is already connected
      const connectedDevices = await this.bluetoothService.getConnectedDevices();
      if (connectedDevices.length > 0) {
        this.connectedDevice = await this.bluetoothService.reconnectToDevice(storedDevice);
        this.connectedDevice$.next(storedDevice);
        // in case the device is already connected, update the device data
        if (this.connectedDevice.status === DeviceStatus.CONNECTED) {
          this.updateDeviceData(this.connectedDevice);
        }
        // otherwise, try to connect
        if (this.connectedDevice.status === DeviceStatus.CONNECTING) {
          this.connectToDevice(this.connectedDevice);
        }
      } else {
        storedDevice.status = DeviceStatus.CONNECTING;
        this.connectedDevice = storedDevice;
        this.connectedDevice$.next(storedDevice);
        // stored device is not connected, then try to get previously connected devices
        const previouslyConnectedDevices = await this.bluetoothService.getPreviouslyConnectedDevices([
          storedDevice.deviceId
        ]);
        if (previouslyConnectedDevices.length > 0) {
          this.connectToDevice(storedDevice);
        } else {
          storedDevice.status = DeviceStatus.NOTCONNECTED;
          this.connectedDevice = storedDevice;
          this.connectedDevice$.next(storedDevice);
        }
      }
    } else {
      this.connectedDevice = null;
      this.connectedDevice$.next(null);
    }
  }

  /*
   * Method used to update device data periodically.
   */
  private async updateDeviceData(device: Device): Promise<void> {
    if (device.status === DeviceStatus.CONNECTED) {
      this.connectedDevice = await this.bluetoothService.fetchDeviceData(device);
      this.connectedDevice$.next(this.connectedDevice);
      // check if battery level is low
      await this.checkBatteryLevel(this.connectedDevice);
    }
  }

  /*
   * Callback used when connection between device and app is lost.
   */
  private async onDisconnect(): Promise<void> {
    console.log('disconnected from device: ', this.connectedDevice);
    if (this.connectedDevice) {
      this.connectedDevice.status = DeviceStatus.NOTCONNECTED;
      this.connectedDevice.rssi = DEFAULT_RSSI;
      this.connectedDevice.batteryLevel = null;
      this.connectedDevice$.next(this.connectedDevice);
    }
  }

  /*
   * Method used to check if battery level is low.
   */
  private async checkBatteryLevel(device: Device): Promise<void> {
    const threshold = await this.remoteConfig.getNumber(RemoteConfig.BATTERY_LEVEL_THRESHOLD);
    console.log('🚀 ~ DevicesService ~ checkBatteryLevel ~ threshold:', threshold);
    console.log('🚀 ~ DevicesService ~ checkBatteryLevel ~ device.batteryLevel:', device.batteryLevel);
    if (device.batteryLevel && device.batteryLevel < threshold && !this.batteryNotificationSent && device.id) {
      this.bluetoothService.notifications$.next({
        characteristicId: null,
        deviceId: device.id,
        serviceId: null,
        type: NotificationType.DEVICE_BATTERY_LEVEL,
        value: device.batteryLevel
      });
      this.batteryNotificationSent = true;
    } else if (device.batteryLevel && device.batteryLevel > threshold) {
      this.batteryNotificationSent = false;
    }
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum NotificationType {
  DEVICE_BACKGROUND = 'device_background',
  DEVICE_BACKGROUND_IOS = 'device_background_ios',
  DEVICE_BATTERY_LEVEL = 'device_battery_level',
  DEVICE_CONNECTION = 'device_connection',
  DEVICE_EMERGENCY = 'device_emergency',
  SILENT_EMERGENCY = 'silent_emergency',
  USER_CREDITS = 'user_credits' // TODO remove this, and create own service taking care of this
}

export const DEFAULT_RSSI = -115;
export const DEFAULT_SERVICE = '0000feff-0000-1000-8000-00805f9b34fb';
// service
export const GENERIC_ACCESS_SERVICE = '00001800-0000-1000-8000-00805f9b34fb';
export const STOP_DEVICE_SERVICE = '0000fff0-0000-1000-8000-00805f9b34fb'; // responsible for buzzer with characteristic 0783b03e-8535-b5a0-7140-a304d2495cb8
export const BATTERY_READ_CHARACTERISTIC = '00002a19-0000-1000-8000-00805f9b34fb';
export const GENERIC_ATTRIBUTE_SERVICE = '00001801-0000-1000-8000-00805f9b34fb';
export const BATTERY_DEVICE_SERVICE = '0000180f-0000-1000-8000-00805f9b34fb';
// characteristic
export const STOP_READ_CHARACTERISTIC = '0783b03e-8535-b5a0-7140-a304d2495cba';
export const STOP_READ_NOTIFY_CHARACTERISTIC = '0783b03e-8535-b5a0-7140-a304d2495cb8';
export const STOP_WRITE_CHARACTERISTIC = '0783b03e-8535-b5a0-7140-a304d2495cba';
// value
export const STOP_BUZZER_READ_VALUE = 90;
export const STOP_BUZZER_WRITE_VALUE = [0x5a, 0x01, 0x01, 0x00, 0x00, 0x00, 0x00, 0x3f];
export const STOP_LED_START_VALUE = [0x5a, 0x01, 0x02, 0x00, 0x00, 0x00, 0x00, 0x3f];
export const STOP_LED_STOP_VALUE = [0x5a, 0x01, 0x03, 0x00, 0x00, 0x00, 0x00, 0x3f];
export const STOP_SOUND_START_VALUE = [0x5a, 0x01, 0x04, 0x00, 0x00, 0x00, 0x00, 0x3f];
export const STOP_SOUND_STOP_VALUE = [0x5a, 0x01, 0x05, 0x00, 0x00, 0x00, 0x00, 0x3f];

import { BleDevice, BleService, ScanResult } from '@capacitor-community/bluetooth-le';
import { DeviceStatus } from '@enums/device-status.enum';
import { NotificationType } from '@app/enums/notification-type.enum';

export class Device {
  public alarmIsOn?: boolean;
  public batteryLevel?: number | null;
  public deviceId!: string;
  public id?: string;
  public ledIsOn?: boolean;
  public name: string | undefined;
  public rssi?: number;
  public services?: BleService[];
  public status: DeviceStatus | undefined;
  public statusError?: string | null;
}

export class DeviceService {
  public id: string | undefined;
  public characteristic: string | undefined;
  public name: string | undefined;
}

export class DeviceNotification {
  public type!: NotificationType;
  public deviceId?: string;
  public serviceId?: string | null | undefined;
  public characteristicId?: string | null | undefined;
  public value: number | null | undefined;
}

export interface DeviceDTO {
  name: string | undefined;
  deviceId: string | undefined;
  rssi: number;
  status: DeviceStatus;
}

export const mapDeviceToDTO = (device: Device): DeviceDTO => {
  return {
    name: device.name,
    deviceId: device.deviceId,
    rssi: device.rssi || 0,
    status: device.status || DeviceStatus.DISCONNECTED
  };
};

export const mapScanResultToDevice = (result: ScanResult): Device => {
  return {
    deviceId: result.device.deviceId,
    name: result.device.name,
    rssi: result.rssi,
    services: [],
    status: DeviceStatus.DISCONNECTED
  };
};

export const mapBleDeviceToDevice = (device: BleDevice): Device => {
  return {
    deviceId: device.deviceId,
    status: DeviceStatus.CONNECTED,
    name: device.name
  };
};

import { AppConstants } from '@app/app.constants';
import { AuthStateChange, FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  BATTERY_DEVICE_SERVICE,
  BATTERY_READ_CHARACTERISTIC,
  DEFAULT_RSSI,
  DEFAULT_SERVICE,
  Device,
  DeviceNotification,
  GENERIC_ACCESS_SERVICE,
  GENERIC_ATTRIBUTE_SERVICE,
  STOP_BUZZER_READ_VALUE,
  STOP_DEVICE_SERVICE,
  STOP_LED_START_VALUE,
  STOP_LED_STOP_VALUE,
  STOP_READ_CHARACTERISTIC,
  STOP_READ_NOTIFY_CHARACTERISTIC,
  STOP_SOUND_START_VALUE,
  STOP_SOUND_STOP_VALUE,
  STOP_WRITE_CHARACTERISTIC,
  mapBleDeviceToDevice,
  mapScanResultToDevice
} from '@models/device';
import { BehaviorSubject } from 'rxjs';
import {
  BleClient,
  BleDevice,
  BleService,
  BluetoothLe,
  ScanResult,
  SetCustomConfigOptions,
  numbersToDataView
} from '@capacitor-community/bluetooth-le';
import { Capacitor } from '@capacitor/core';
import { DeviceConnectionError } from '@app/enums/device-connection-error.enum';
import { DeviceSignal } from '@enums/device-signal.enum';
import { DeviceStatus } from '@enums/device-status.enum';
import { Injectable, NgZone, inject } from '@angular/core';
import { NotificationType } from '@app/enums/notification-type.enum';
import { StorageService } from '../storage/storage.service';
import { ToastService } from '@services/toast/toast.service';
import debounce from 'lodash-es/debounce';

@Injectable({
  providedIn: 'root'
})
/*
 * BluetoothService is used to handle all bluetooth related functionality.
 * Enable bluetooth, scan for devices, connect to devices, read and write to devices.
 */
export class BluetoothService {
  public notifications$ = new BehaviorSubject<DeviceNotification | null>(null);
  private connectionFailed = 0;
  private isInitialized = false;
  private readonly ngZone: NgZone = inject(NgZone);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly toastService: ToastService = inject(ToastService);

  constructor() {
    FirebaseAuthentication.addListener('authStateChange', (state: AuthStateChange) => {
      this.ngZone.run(async () => {
        const bleInitialized = await this.storageService.get(AppConstants.BLE_INITIALIZED);
        console.log('🚀 ~ BluetoothService ~ FirebaseAuthentication.addListener ~ bleInitialized:', bleInitialized);
        if (state.user && bleInitialized) {
          this.initializeBle();
        }
      });
    });
  }

  /*
   * Used to initialize the BLE client. This is required before any other BLE methods can be used.
   * In case of an error, the user will be redirected to the setup help page.
   * Errors could relate to the user not having enabled Bluetooth or not having granted location permissions.
   */
  public async initializeBle(): Promise<void> {
    if (!this.isInitialized) {
      try {
        // Check if location is enabled
        if (Capacitor.getPlatform() === 'android') {
          const isLocationEnabled = await BleClient.isLocationEnabled();
          if (!isLocationEnabled) {
            await BleClient.openLocationSettings();
          }
        }
        await BleClient.initialize({ androidNeverForLocation: true });
        this.isInitialized = true;
        this.storageService.set(AppConstants.BLE_INITIALIZED, true);
      } catch (error: any) {
        await this.handleError({
          message: 'toast_ble_permission_denied'
        });
        await BleClient.openAppSettings();
      }
    }
  }

  /*
   * Used to request a LE scan for devices.
   */
  public async searchDevices(scanResults: Set<ScanResult>): Promise<void> {
    await this.initializeBle();
    await this.requestLEScan(scanResults);
  }

  /*
   * Used to update the BLE config.
   */
  public async updateBleConfig(options: SetCustomConfigOptions): Promise<void> {
    await BluetoothLe.setCustomConfig(options);
  }

  /*
   * Used to connect to a device and subscribe to services. Also a listener for disconnects will be setup.
   */
  public async connectToDevice(device: Device, onDisconnect: () => void): Promise<Device> {
    await this.initializeBle();

    // TODO replace with generic disconnect which works for both platforms
    const handleAndroidDisconnect = async (deviceId: string): Promise<void> => {
      if (Capacitor.getPlatform() === 'android') {
        try {
          await BleClient.disconnect(deviceId);
        } catch (error: any) {
          this.handleError(error);
        }
      }
    };

    const handleBleConnect = async (deviceId: string): Promise<void> => {
      try {
        await BleClient.connect(
          deviceId,
          () => {
            this.notifications$.next({
              characteristicId: null,
              deviceId,
              serviceId: null,
              type: NotificationType.DEVICE_CONNECTION,
              value: null
            });
            onDisconnect();
          },
          {
            timeout: 6000
          }
        );
      } catch (error: any) {
        device.statusError = error.message;
        if (error.message === DeviceConnectionError.PEER_REMOVED_PAIRING) {
          await handlePairingError(error);
        } else {
          await this.handleError(error.message);
        }
      }
    };

    const updateDeviceData = async (deviceToUpdate: Device): Promise<Device> => {
      try {
        deviceToUpdate.services = await this.getServicesFromDevice(deviceToUpdate.deviceId);
        deviceToUpdate = await this.fetchDeviceData(deviceToUpdate);
        deviceToUpdate.status = DeviceStatus.CONNECTED;
        this.connectionFailed = 0;
        return deviceToUpdate;
      } catch (error: any) {
        console.log('🚀 ~ BluetoothService ~ updateDeviceData ~ error:', error.message);
        throw Error(error);
      }
    };

    const handleConnectionError = async (error: any): Promise<Device> => {
      await this.handleError(error);
      device.rssi = DEFAULT_RSSI;
      device.status = DeviceStatus.NOTCONNECTED;
      return device;
    };

    const handlePairingRequest = async (): Promise<void> => {
      if (Capacitor.getPlatform() === 'android') {
        // set timeout to wait for the pairing request dialog to appear before continuing
        await new Promise(resolve => setTimeout(resolve, 4000));
      }
    };

    const handlePairingError = async (error: any): Promise<void> => {
      await this.handleError(error);
      device.rssi = DEFAULT_RSSI;
      device.status = DeviceStatus.PEER_REMOVED_PAIRING;
      throw Error(error);
    };

    const handleReconnectWithScan = async (device: Device): Promise<Device> => {
      const results = await this.requestLEScan(new Set<ScanResult>());
      const scannedDevice = Array.from(results).filter(result => result.device.deviceId === device.deviceId);
      if (!scannedDevice[0]) {
        // TODO better error message
        return handleConnectionError({ message: 'Device not found' });
      }
      const mappedDevice = mapScanResultToDevice(scannedDevice[0]);
      await handleAndroidDisconnect(mappedDevice.deviceId);
      await handleBleConnect(mappedDevice.deviceId);
      return await updateDeviceData(mappedDevice);
    };

    try {
      await handleAndroidDisconnect(device.deviceId);
      await handleBleConnect(device.deviceId);
      await handlePairingRequest();
      console.log('🚀 ~ BluetoothService ~ connectToDevice ~ device:', device);
      device = await updateDeviceData(device);
      return device;
    } catch (error: any) {
      console.log('🚀 ~ BluetoothService ~ connectToDevice ~ error:', error);
      // in case connection fails try to reconnect once more using a LE scan
      if (this.connectionFailed === 0) {
        this.connectionFailed++;
        return await handleReconnectWithScan(device);
      } else {
        return handleConnectionError(error);
      }
    }
  }

  /*
   * Used to setup listener and fetch data from the device, when device is already connected (or reconnected).
   */
  public async reconnectToDevice(device: Device): Promise<Device> {
    await this.initializeBle();
    try {
      // manually fetching data here, because fetchDevice Data will fail differently in case deviceId is invalid
      device.rssi = await this.getRssiFromDevice(device.deviceId);
      device.batteryLevel = await this.readFromService(
        device.deviceId,
        BATTERY_DEVICE_SERVICE,
        BATTERY_READ_CHARACTERISTIC
      );
      device.status = DeviceStatus.CONNECTED;
      return device;
    } catch (error: any) {
      device.rssi = DEFAULT_RSSI;
      device.status = DeviceStatus.CONNECTING;
      return device;
    }
  }

  /*
   * Used to retrieve previously connected devices without a LE Scan.
   */
  public async getPreviouslyConnectedDevices(deviceIds: string[]): Promise<BleDevice[]> {
    await this.initializeBle();
    try {
      return await BleClient.getDevices(deviceIds);
    } catch (error: any) {
      await this.handleError(error);
      return [];
    }
  }

  /*
   * Used to retrieve already connected devices. In addition it will query for the RSSI and battery level and subscribe service notifications.
   */
  public async getConnectedDevices(): Promise<Device[]> {
    await this.initializeBle();
    try {
      const devices: BleDevice[] = await BleClient.getConnectedDevices([
        BATTERY_DEVICE_SERVICE,
        GENERIC_ACCESS_SERVICE,
        GENERIC_ATTRIBUTE_SERVICE,
        STOP_DEVICE_SERVICE
      ]);
      const connectedDevices = devices
        .filter((device: BleDevice) => device.name === AppConstants.DEVICE_NAME)
        .map((device: BleDevice) => mapBleDeviceToDevice(device));
      return connectedDevices;
    } catch (error: any) {
      console.log('🚀 ~ file: bluetooth.service.ts:154 ~ BluetoothService ~ getConnectedDevices ~ error:', error);
      await this.handleError(error);
      return [];
    }
  }

  /*
   * Used to retrieve data from connected devices.
   */
  public async fetchDeviceData(device: Device): Promise<Device> {
    try {
      device.rssi = await this.getRssiFromDevice(device.deviceId);
      device.batteryLevel = await this.readFromService(
        device.deviceId,
        BATTERY_DEVICE_SERVICE,
        BATTERY_READ_CHARACTERISTIC
      );
      return device;
    } catch (error: any) {
      console.log('🚀 ~ file: bluetooth.service.ts:229 ~ BluetoothService ~ fetchDeviceData ~ error:', error);
      device.rssi = DEFAULT_RSSI;
      device.batteryLevel = 0;
      device.status = DeviceStatus.NOTCONNECTED;
      return device;
    }
  }
  /*
   * Used to disconnect from a device.
   */
  public async disconnectDevice(device: Device): Promise<void> {
    try {
      await this.unSubscribeFromService(device, STOP_DEVICE_SERVICE, STOP_WRITE_CHARACTERISTIC);
      await BleClient.disconnect(device.deviceId);
    } catch (error: any) {
      console.log(`Disconnecting failed for device : ${device.id}`, error);
      await this.handleError(error);
    }
  }

  /*
   * Used to determine the signal strength of a device based on the RSSI.
   */
  public determineSignalStrength(rssi: number | null | undefined): DeviceSignal {
    if (rssi === undefined || rssi === null) {
      return DeviceSignal.NONE;
    }

    if (rssi >= -70) {
      return DeviceSignal.EXCELLENT;
    } else if (rssi < -70 && rssi >= -85) {
      return DeviceSignal.GOOD;
    } else if (rssi < -85 && rssi >= -100) {
      return DeviceSignal.FAIR;
    } else if (rssi < 100 && rssi >= -110) {
      return DeviceSignal.POOR;
    } else {
      return DeviceSignal.NONE;
    }
  }

  /*
   * Used to fetch alarm data
   */
  public async fetchAlarmData(device: Device): Promise<number | null> {
    try {
      return await this.readFromService(device.deviceId, STOP_DEVICE_SERVICE, STOP_READ_CHARACTERISTIC);
    } catch (error: any) {
      console.log('🚀 ~ file: bluetooth.service.ts:229 ~ BluetoothService ~ fetchAlarmData ~ error:', error.message);
      return null;
    }
  }

  /*
   * Used to subscribe to a service on a device.
   * If notification is received, the value will be send to the notification$ subject.
   */
  public async subscribeToService(device: Device, serviceId?: string, characteristicId?: string): Promise<void> {
    try {
      await BleClient.startNotifications(
        device.deviceId,
        serviceId || STOP_DEVICE_SERVICE,
        characteristicId || STOP_READ_NOTIFY_CHARACTERISTIC,
        (value: DataView) => {
          this.ngZone.run(async () => {
            await this.handleNotification(
              device,
              serviceId || STOP_DEVICE_SERVICE,
              characteristicId || STOP_READ_NOTIFY_CHARACTERISTIC,
              value.getUint8(0)
            );
          });
        }
      );
    } catch (error: any) {
      console.log(`Not able to subscribe to notification of service: ${serviceId}`);
      return;
    }
  }

  /*
   * Used to unsubscribe from services of a device
   */
  public async unSubscribeFromService(device: Device, serviceId?: string, characteristicId?: string): Promise<void> {
    try {
      await BleClient.stopNotifications(
        device.deviceId,
        serviceId || STOP_DEVICE_SERVICE,
        characteristicId || STOP_READ_NOTIFY_CHARACTERISTIC
      );
    } catch (error: any) {
      console.log(`Unsubscribing from notifications failed for service: ${serviceId}`);
      return;
    }
  }

  /*
   * Used to request permissions for bluetooth and location.
   */
  public async requestPermissions(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await this.initializeBle();
    }
  }

  /*
   * Used to determine if bluetooth is enabled and access is granted.
   */
  public async checkPermissions(): Promise<boolean> {
    try {
      return await BleClient.isEnabled();
    } catch (error: any) {
      return false;
    }
  }

  /*
   * Used to enable/disable LED flashlight on device.
   */
  public async toggleLight(device: Device, ledIsOn: boolean): Promise<void> {
    await this.writeToService(
      device.deviceId,
      STOP_DEVICE_SERVICE,
      STOP_WRITE_CHARACTERISTIC,
      ledIsOn ? STOP_LED_STOP_VALUE : STOP_LED_START_VALUE
    );
  }

  /*
   * Used to enable/disable buzzer sound on device.
   */
  public async toggleAlarm(device: Device, alarmIsOn: boolean): Promise<void> {
    await this.writeToService(
      device.deviceId,
      STOP_DEVICE_SERVICE,
      STOP_WRITE_CHARACTERISTIC,
      alarmIsOn ? STOP_SOUND_STOP_VALUE : STOP_SOUND_START_VALUE
    );
  }

  /*
   * Used to return info is device is bonded or not
   */
  public async isBonded(deviceId: string): Promise<boolean> {
    try {
      return Capacitor.getPlatform() ? await BleClient.isBonded(deviceId) : true;
    } catch (error: any) {
      console.log('🚀 ~ file: bluetooth.service.ts:329 ~ BluetoothService ~ isBonded ~ error:', error);
      return false;
    }
  }

  /*
   * Used to retrieve the services from a device.
   */
  public async getServicesFromDevice(deviceId: string): Promise<BleService[]> {
    const services = await BleClient.getServices(deviceId);
    console.log('🚀 ~ BluetoothService ~ getServicesFromDevice ~ services:', services);
    return services;
  }

  /*
   * Used to request a LE scan for devices. The scan will be stopped after 6 seconds.
   */
  private async requestLEScan(scanResults: Set<ScanResult>): Promise<Set<ScanResult>> {
    return new Promise((resolve, reject) => {
      try {
        BleClient.requestLEScan(
          {
            services: [DEFAULT_SERVICE]
          },
          (result: ScanResult) => {
            if (result && result.device && result.device.deviceId) {
              scanResults.add(result);
            }
          }
        );
        setTimeout(async () => {
          // stop scan after 7 seconds
          await BleClient.stopLEScan();
          // sort scan results by rssi
          const sortedScanResults = Array.from(scanResults).sort(
            (a: ScanResult, b: ScanResult) => (a.rssi || 0) - (b.rssi || 0)
          );
          resolve(new Set(sortedScanResults));
        }, 7000);
      } catch (error) {
        this.handleError(error);
        reject();
      }
    });
  }

  /*
   * Used to handle device notifications
   */
  private async handleNotification(
    device: Device,
    serviceId: string,
    characteristicId: string,
    value: number
  ): Promise<void> {
    if (serviceId === STOP_DEVICE_SERVICE && value === STOP_BUZZER_READ_VALUE && device.id) {
      this.debouncedNotification({
        characteristicId: characteristicId,
        deviceId: device.id,
        serviceId: serviceId,
        type: NotificationType.DEVICE_EMERGENCY,
        value
      });
    }
  }

  /*
   * Used to debounce notifications to avoid multiple notifications in a short time.
   */
  private debouncedNotification = debounce(
    notification => {
      this.notifications$.next(notification);
    },
    5000,
    { leading: true, trailing: false }
  ); // Debounce time in milliseconds

  /*
   * Used to read from a service on a device.
   */
  private async readFromService(deviceId: string, serviceId: string, characteristicId: string): Promise<number | null> {
    try {
      const value = await BleClient.read(deviceId, serviceId, characteristicId);
      return value ? value.getUint8(0) : null;
    } catch (error: any) {
      console.log(`Reading from service failed for service: ${serviceId}`, error.message);
      return null;
    }
  }

  /*
   * Used to write data to service/characteristic of connected devices.
   */
  private async writeToService(
    deviceId: string,
    serviceId: string,
    characteristicId: string,
    value: number[]
  ): Promise<void> {
    try {
      const dataView = numbersToDataView(value);
      await BleClient.write(deviceId, serviceId, characteristicId, dataView);
    } catch (error: any) {
      console.log(`Writing to service ${serviceId} with characteristicId ${characteristicId} with an error: `, error);
      return;
    }
  }

  /*
   * Used to retrieve the RSSI (signal strength) from a device.
   */
  private async getRssiFromDevice(deviceId: string): Promise<number> {
    const rssi = await BleClient.readRssi(deviceId);
    return rssi;
  }

  /*
   * In case of an error, a toast message with the current error will be shown and the user will be redirected to the setup help page.
   */
  private async handleError(error?: any): Promise<void> {
    let text = 'toast_ble_setup_error';
    if (error) {
      const message = error.message ? error.message.replace(/Error: /g, '') : error;
      switch (message) {
        case DeviceConnectionError.TIMEOUT_DISCONNECTION:
        case DeviceConnectionError.TIMEOUT_ANDROID:
        case DeviceConnectionError.TIMEOUT_IOS:
          text = 'toast_ble_connection_timeout';
          break;
        case DeviceConnectionError.NOT_CONNECTED:
        case DeviceConnectionError.NOT_FOUND:
          text = 'toast_ble_device_not_connected';
          break;
        case DeviceConnectionError.PEER_REMOVED_PAIRING:
          text = 'toast_ble_device_peer_removed_pairing';
          break;
        default:
          text = error.message;
          break;
      }
    }
    // catch different error types and show a toast message
    await this.toastService.showToast({
      text,
      color: 'danger'
    });
  }
}
